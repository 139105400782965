<template>
  <div class="material_manage">
    <div class="tabs">
      <div class="tab_navs">
        <div class="tab_nav" :class="active == 1 ? 'active' : ''" @click="switchNav(1)"><div class="content">素材</div></div>
        <div class="tab_nav" :class="active == 2 ? 'active' : ''" @click="switchNav(2)"><div class="content">画板</div></div>
      </div>
      <div class="tab_panes">
        <!-- 素材 -->
        <div class="tab_pane colect_manage" v-show="active == 1">
          <!-- 素材筛选 -->
          <div class="collect_filter clearfix">
            <div class="column"><el-checkbox v-model="selectAll" :border="border" @change="checkedAll">全选</el-checkbox></div>
            <div class="column"><el-button type="danger" @click="delCollect">批量删除</el-button></div>
            <div class="column"><el-button type="success">批量显示</el-button></div>
            <div class="column"><el-button type="info">批量隐藏</el-button></div>
            <div class="column">
              <el-popover placement="bottom" width="400" trigger="click" v-model="showClassify">
                <el-tag 
                  :type="classifyIndex == index ? '' : 'info'"
                  v-for="(tag, index) in classifyList"
                  :key="index"
                  :effect="classifyIndex == index ? 'dark' : 'light'"
                  class="class_tag"
                  @click.stop="seatchByClassify(index)">
                  {{ tag.title }}
                </el-tag>
                <el-button slot="reference" type="primary">
                  所属分类：{{ classifyList[classifyIndex] ? classifyList[classifyIndex].title : '' }} <i class="el-icon-caret-bottom"></i>
                </el-button>
              </el-popover>
            </div>
            <div class="column">
              <el-button @click="switchUse">
                <span :class="collectQ.use == 0 ? 'high_light' : 'low_light'">系统素材</span>
                <i class="iconfont iconicon-" style="margin: 0 6px;"></i>
                <span :class="collectQ.use == 1 ? 'high_light' : 'low_light'">用户素材</span>
              </el-button>
            </div>
            <div class="column search">
              <el-input class="s_input" v-model="collectQ.title" placeholder="关键词搜索" @keyup.native.enter="searchByTitle"></el-input>
              <el-input class="s_input" v-model="collectQ.username" placeholder="用户搜索" @keyup.native.enter="searchByUser"></el-input>
            </div>
          </div>
          <!-- 有素材 -->
          <div class="collect_list" v-loading="loading" v-if="collectList.length > 0">
            <div class="collect_box" v-for="(item, index) in collectList" :key="index" @click="viewDetails(item.id)">
              <div class="collect">
                <div class="img_box">
                  <el-image :src="item.url" fit="cover">
                    <div slot="placeholder" class="tip">
                      加载中<i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                </div>
                <div class="info">
                  <div class="desc">{{ item.info }}</div>
                  <div class="clearfix">
                    <div class="classify">分类: {{ item.cat_title }}</div>
                    <div class="created">上传时间: {{ item.created ? item.created.substring(0, 10).replace(/-/g, '.') : '未知' }}</div>
                  </div>
                </div>
                <div class="state" @click.stop="changeState(index)">
                  <i class="iconfont iconweixuanzhong"></i>
                  <i class="iconfont iconxuanzhong" v-show="item.checked"></i>
                </div>
                <div class="ascription" v-if="item.username">{{ item.username }}</div>
                <div class="edit" @click.stop>
                  <el-button type="primary" plain size="small" @click="editMaterial(item)">编辑</el-button>
                  <el-button type="danger" plain size="small" @click="delCollect(item.id)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- 无素材 -->
          <div class="no_collect" v-if="collectList.length == 0">找不到任何素材</div>
          <!-- 分页 -->
          <el-pagination
            class="collect_page"
            background
            @current-change="handleCurrentChange" 
            :current-page="collectQ.page"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            :total="collectCount">
          </el-pagination>
          <!-- 编辑素材 -->
          <el-dialog title="编辑素材" :visible.sync="mVisible" :close-on-click-modal="closeOnClickModal">
            <el-container>
              <el-container>
                <el-main>
                  <div class="base_info">
                    <div class="tags_box">
                      <div class="title">标签:</div>
                      <div class="tags">
                        <el-tag v-for="(tag, index) in material.tags" :key="index" closable type="info" @close="handleClose(tag)">{{tag}}</el-tag>
                        <el-input
                          class="input-new-tag"
                          v-if="tagVisible"
                          v-model="tagText"
                          ref="saveTagInput"
                          size="small"
                          @keyup.enter.native="generateTag"
                          @blur="generateTag"
                        >
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small" @click="showInputTag">+ 新标签</el-button>
                      </div>
                    </div>
                    <div class="info_box">
                      <div class="title">描述:</div>
                      <el-input type="textarea" resize="none" placeholder="请输入描述文本" v-model="material.info" maxlength="30" show-word-limit></el-input>
                    </div>
                    <div class="board_box">
                      <div class="title">画板:</div>
                      <el-select v-model="material.board_id" placeholder="请选择画板">
                        <el-option v-for="item in userBoard" :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </div>
                </el-main>
                <el-aside>
                <div class="edit_preview">
                  <el-image :src="material.url">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                </el-aside>
              </el-container>
              <el-footer>
                <div class="actions">
                  <el-button type="primary">保存</el-button>
                  <el-button type="info" @click="mVisible = false">取消</el-button>
                  <el-button type="danger">删除</el-button>
                </div>
              </el-footer>
            </el-container>
          </el-dialog>
        </div>

        <!-- 画板 -->
        <div class="tab_pane board_manage" v-show="active == 2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'material_manage',
  data() {
    return {
      active: 1,
      border: true,
      collectQ: {cat_id: '', username: '', ord: 0, title: '', use: 0, page: 1}, // 素材请求参
      collectList: [], // 素材分页数据
      collectCount: 0, // 素材总数
      loading: false,
      showClassify: false,  // 显示全部分类
      classifyList: [], // 分类
      classifyIndex: 0, // 分类索引
      mVisible: false, // 素材编辑框显示状态
      closeOnClickModal: false,
      material: { id: '', url: '', info: '', board_id: 1, tags: [] }, // 素材编辑数据
      tagVisible: false, // 素材标签输入显示状态
      tagText: '', // 素材标签输入文本
      userBoard: [], // 用户画板数据
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.collectList.length > 0 && this.collectList.every(item => item.checked == true);
      },
      set() {}
    },
  },
  methods: {
    switchNav(i) {  // 切换展示内容
      this.active = i;
    },
    getClassifyData() {  // 全部分类
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/catlist',
        method: 'GET',
      }).then(res => {
        if (res.code == 200) {
          this.classifyList = res.data ? res.data : [];
          this.classifyList.unshift({id: '', title: '全部'});
        }
      })
    },
    getCollectList() {  // 素材数据
      this.loading = true;
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectQ.cat_id,
          username: this.collectQ.username,
          ord: this.collectQ.ord,
          title: this.collectQ.title,
          use: this.collectQ.use,
          page: this.collectQ.page,
        },
      }).then(res => {
        this.loading = false;
        if (res.code == 200) {
          this.collectList = res.data.res ? res.data.res.map(item => {item.checked = false;return item;}) : [];
          this.collectCount = res.data.count;
        }
      })
    },
    viewDetails(id) { // 采集详情
      let routeObj = this.$router.resolve({
        path: '/CollectDetails',
        query: { pins: id }
      })
      window.open(routeObj.href, '_blank');
    },
    checkedAll(val) { // 全选
      if (val) {
        this.collectList.map(item => item.checked = true);
      } else {
        this.collectList.map(item => item.checked = false);
      }
    },
    seatchByClassify(i) { // 分类搜索
      this.showClassify = false;
      this.classifyIndex = i;
      this.collectQ.cat_id = this.classifyList[i].id;
      this.collectQ.page = 1;
      this.getCollectList();
    },
    searchByTitle() { // 关键词搜索
      this.collectQ.page = 1;
      this.getCollectList();
    },
    searchByUser() {  // 用户名搜索
      this.collectQ.page = 1;
      this.getCollectList();
    },
    switchUse() { // 用户采集/系统采集
      if (this.collectQ.use == 0) {
        this.collectQ.use = 1;
        this.collectQ.page = 1;
        this.getCollectList();
      } else if (this.collectQ.use == 1) {
        this.collectQ.use = 0;
        this.collectQ.page = 1;
        this.getCollectList();
      }
    },
    changeState(index) {  // 改变状态
      this.collectList[index].checked = !this.collectList[index].checked;
    },
    handleCurrentChange(val) {  // 分页
      this.collectQ.page = val;
      this.getCollectList();
    },
    delCollect(id) {  // 删除素材
      let ids = '';
      typeof id === 'number' ? ids = id.toString() : ids = this.collectList.filter(item => item.checked == true).map(item => item.id).toString();
      if (!ids) return false;
      this.$confirm('此操作将永久删除所选素材, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$service.DEFAULT({
          url: '/admin/fodder/handler',
          method: 'DELETE',
          params: {
            ids,
          },
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '素材删除成功!',
              type: 'success',
            });
            this.getCollectList();
          } else {
            this.$message.error(res.msg ? res.msg : '未知错误');
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    editMaterial(data) { // 编辑素材
      this.mVisible = true;
      this.material.id = data.id ? data.id : '';
      this.material.url = data.url ? data.url : '';
      this.material.info = data.info ? data.info : '';
      this.material.board_id = data.board_id ? data.board_id : 2;
      this.material.tags = data.tags ? data.tags : ['哈哈哈', '嘻嘻嘻'];
    },
    handleClose(tag) {  // 删除素材标签
      this.material.tags.splice(this.material.tags.indexOf(tag), 1);
    },
    showInputTag() {  // 显示素材标签输入
      this.tagVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    generateTag() { // 新增素材标签
      let tagText = this.tagText;
      if (tagText) {
        this.material.tags.push(tagText);
      }
      this.tagVisible = false;
      this.tagText = '';
    },
  },
  created() {
    this.getClassifyData();
    this.getCollectList();
  },
};
</script>

<style lang="scss" scoped>
.high_light {
  color: #444;
}
.low_light {
  color: #ccc;
}
.material_manage {
  box-sizing: border-box;
  padding: 20px 40px;
  min-width: 1116px;
  height: 100vh;
  overflow: auto;
  cursor: default;
  .tabs {
    .tab_navs {
      z-index: 1;
      .tab_nav {
        display: inline-block;
        margin-right: 20px;
        position: relative;
        &::before {
          display: table;
          content: "";
          width: 20px;
          height: 20px;
          background: #f4f2f7;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          right: 100%;
          z-index: 1;
        }
        &::after {
          display: table;
          content: "";
          width: 20px;
          height: 20px;
          background:  #f4f2f7;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 100%;
          z-index: 1;
        }
        &.active {
          .content {
            border-color: #fff;
            border-radius: 16px 16px 0 0;
            color:#009ad6;
            &::before {
              display: table;
              content: "";
              width: 10px;
              height: 10px;
              background: #fff;
              position: absolute;
              bottom: 0;
              right: 100%;
            }
            &::after {
              display: table;
              content: "";
              width: 10px;
              height: 10px;
              background: #fff;
              position: absolute;
              bottom: 0;
              left: 100%;
            }
          }
        }
        &:first-child::before {
          display: none;
        }
        &:first-child {
          .content::before {
            display: none;
          }
        }
        .content {
          box-sizing: content-box;
          background: #fff;
          background-clip: padding-box;
          padding: 10px 18px;
          border: 5px solid transparent;
          border-radius: 16px;
          transition: all .2s;
        }
      }
    }
    .tab_panes {
      min-height: 600px;
      background: #fff;
      padding: 15px;
      box-shadow: 0 0 8px rgba(0,0,0,0.02);
      border-radius: 0 0 16px 16px;
      position: relative;
      .colect_manage {
        .collect_filter {
          margin-bottom: 15px;
          .column {
            float: left;
            margin-right: 30px;
          }
          .search {
            float: right;
            margin-right: 0;
            ::v-deep .s_input {
              width: auto;
              margin-right: 8px;
              .el-select {
                .el-input__inner {
                  width: 90px;
                }
              }
            }
          }
        }
        .collect_list {
          margin-bottom: 40px;
          .collect_box {
            box-sizing: border-box;
            display: inline-block;
            width: 20%;
            padding: 0 15px;
            margin-bottom: 15px;
            .collect {
              box-sizing: border-box;
              width: 100%;
              border: 1px solid transparent;
              border-radius: 4px;
              position: relative;
              transition: all .12s;
              cursor: pointer;
              &:hover {
                border: 1px solid #2a5caa;
                transform: scale(1.02, 1.02);
              }
              .img_box {
                height: 300px;
                text-align: center;
                .el-image {
                  height: 100%;
                  .tip {
                    padding-top: 135px;
                  }
                }
              }
              .info {
                padding: 12px;
                .desc {
                  font-weight: 700;
                  margin-bottom: 6px;
                }
                .classify {
                  float: left;
                  font-size: 0.875rem;
                  color: #999;
                }
                .created {
                  float: right;
                  font-size: 0.875rem;
                  color: #999;
                }
              }
              .state {
                position: absolute;
                top: 6px;
                left: 6px;
                color: #2a5caa;
                .iconweixuanzhong {
                  font-size: 1.5rem;
                }
                .iconxuanzhong {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  font-size: 1.5rem;
                }
              }
              .ascription {
                position: absolute;
                left: 6px;
                top: 260px;
                padding: 5px 10px;
                background: rgba(0,0,0,0.3);
                color: #fff;
                border-radius: 20px;
              }
              .edit {
                position: absolute;
                right: 6px;
                top: 260px;
              }
            }
          }
        }
        .no_collect {
          padding-top: 200px;
          text-align: center;
          color: #999;
        }
        .collect_page {
          position: absolute;
          bottom: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

/* 分类标签 */
.class_tag {
  margin-right: 20px;
  cursor: pointer;
}

/* 素材编辑 */
.base_info {
  height: 100%;
  .tags_box {
    margin-bottom: .75rem;
    .title {
      margin-bottom: .3em;
    }
    .tags {
      padding: 1rem;
      border: 1px solid #ededed;
      border-radius: 1%;
      min-height: 100px;
      .el-tag,
      .button-new-tag {
        margin-right: 20px;
      }
    }
  }
  .info_box {
    margin-bottom: .75rem;
    .title {
      margin-bottom: .3em;
    }
  }
  .board_box {
    .title {
      margin-bottom: .3em;
    }
    .el-select {
      width: 100%;
    }
  }
}
.edit_preview {
  max-height: 50vh;
  .el-image {
    border: 1px solid #ccc;
  }
}
.actions {
  text-align: center;
}
</style>